import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [

    {
        path: '/recuperar-senha/:hash',
        name: 'Recuperar Senha',
        component: () => import('./pages/Recuperar.vue')
    },
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            //dashboard
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            //Usuários
            {
                path: '/usuarios',
                name: 'Usuários',
                component: () => import('./paginas/usuarios/index.vue')
            },
            //Fornecedores
            {
                path: '/fornecedores',
                name: 'Fornecedores',
                component: () => import('./paginas/fornecedores/index.vue')
            },
            //Importar Planilha
            {
                path: '/importar-planilha',
                name: 'Importar Planilha',
                component: () => import('./paginas/importar/index.vue')
            },
            //Empresas
            {
                path: '/empresas',
                name: 'Empresas',
                component: () => import('./paginas/empresas/index.vue')
            },
            //Tipos de Planilhas
            {
                path: '/tipos-planilhas',
                name: 'Tipos de Planilhas',
                component: () => import('./paginas/planilhas/index.vue')
            },
            //Áreas
            {
                path: '/areas',
                name: 'Áreas',
                component: () => import('./paginas/areas/index.vue')
            },
            //Tipos
            {
                path: '/tipos',
                name: 'Tipos',
                component: () => import('./paginas/tipos/index.vue')
            },
            //Tipos Faturas
            {
                path: '/faturas/tipos',
                name: 'Tipos Faturas',
                component: () => import('./paginas/tipos_faturas/index.vue')
            },
            //Regional
            {
                path: '/regional',
                name: 'Regional',
                component: () => import('./paginas/regional/index.vue')
            },
            //Faturas
            {
                path: '/faturas/implantacao',
                name: 'Faturas Implantacao',
                component: () => import('./paginas/faturas/implantacao.vue')
            },
            {
                path: '/faturas/implantar',
                name: 'Faturas Implantar',
                component: () => import('./paginas/faturas/implantar.vue')
            },
            {
                path: '/faturas/fila',
                name: 'Fila',
                component: () => import('./paginas/faturas/fila.vue')
            },
            {
                path: '/faturas/cockpit',
                name: 'Cockpit',
                component: () => import('./paginas/faturas/cockpit.vue')
            },
            //Dicionario
            {
                path: '/faturas/itens-chaves',
                name: 'Itens Chaves',
                component: () => import('./paginas/faturas/itens-chaves.vue')
            },
            {
                path: '/faturas/itens-vinculados',
                name: 'Itens Vinculados',
                component: () => import('./paginas/faturas/itens-vinculados.vue')
            },
            {
                path: '/faturas/vincular',
                name: 'Vincular',
                component: () => import('./paginas/faturas/dicionario.vue')
            },
            
            //Relatório de Faturas
            {
                path: '/relatorios/faturas',
                name: 'Relatório de Faturas',
                component: () => import('./paginas/relatorios/faturas.vue')
            },
            //Relatório de Base Cadastral
            {
                path: '/relatorios/base',
                name: 'Relatório de Base Cadastral',
                component: () => import('./paginas/relatorios/base.vue')
            },
            //Relatório de Energia Injetada
            {
                path: '/energia',
                name: 'Relatório de Energia Injetada',
                component: () => import('./paginas/energia/energia.vue')
            },
            //Importar de Energia Injetada
            {
                path: '/importar',
                name: 'Importar de Energia Injetada',
                component: () => import('./paginas/importar/index.vue')
            },
            //Base 1 - Importada
            {
                path: '/base1',
                name: 'Base 1 - Importada',
                component: () => import('./paginas/planilhas/base1.vue')
            },
            //Base 2 - Importada
            {
                path: '/base2',
                name: 'Base 2 - Importada',
                component: () => import('./paginas/planilhas/base2.vue')
            },
            //Base 3 - Importada
            {
                path: '/base3',
                name: 'Base 3 - Importada',
                component: () => import('./paginas/planilhas/base3.vue')
            },
            //Importar Planilha Fornecedor
            {
                path: '/fornecedor-planilha',
                name: 'Importar Planilha Fornecedor',
                component: () => import('./paginas/fornecedores/importar.vue')
            },
            //Planilha Fornecedor Importada
            {
                path: '/planilha-importada',
                name: 'Planilha Fornecedor Importada',
                component: () => import('./paginas/planilhas/fornecedor.vue')
            },
            //Relatório Consolidação
            {
                path: '/consolidacao',
                name: 'Relatório Consolidação',
                component: () => import('./paginas/relatorios/consolidacao.vue')
            },
            // Concessionarias
            {
                path: '/concessionarias',
                name: 'Concessionarias',
                component: () => import('./paginas/concessionarias/dadosbasicos.vue')
            },
            //Concessionárias Tipos
            {
                path: '/concessionarias/tipos',
                name: 'Concessionarias-tipos',
                component: () => import('./paginas/concessionarias/tipos.vue')
            },
            // Unidades
            {
                path: '/unidades/dados-basicos',
                name: 'Unidades',
                component: () => import('./paginas/unidades/dadosbasicos.vue')
            },
            {
                path: '/unidades/tipos-tensao',
                name: 'Unidades Tipos de Tensão',
                component: () => import('./paginas/unidades/tiposdetensao.vue')
            },
            {
                path: '/unidades/tipos-de-insumo',
                name: 'Unidades Tipos de Insumos',
                component: () => import('./paginas/unidades/tiposdeinsumos.vue')
            },
            // Tipos de modadalidade tarifaria
            {
                path: '/unidades/tipos-modalidade-tarifaria',
                name: 'Tipos de modalidade tarifaria',
                component: () => import('./paginas/unidades/tiposmodalidadetarifaria.vue')
            },
            // Classes Tarifária
            {
                path: '/unidades/tipos-classes-tarifarias',
                name: 'Classes Tarifária',
                component: () => import('./paginas/unidades/classestarifaria.vue')
            },
            // Tipos de Sub Classe
            {
                path: '/unidades/tipos-subclasse',
                name: 'Tipos de ',
                component: () => import('./paginas/unidades/tipossubclasse.vue')
            },
            // Sub Mercado
            {
                path: '/unidades/tipos-submercado',
                name: 'Sub-Mercado',
                component: () => import('./paginas/unidades/submercado.vue')
            }


        ]
    },
    {
        path: '/',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    { path: '/:pathMatch(.*)*', component: () => import('./pages/NotFound.vue') },

];

const router = createRouter({
    mode: "history",
    history: createWebHashHistory(),
    routes,
});

export default router;
